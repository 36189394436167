import React from 'react';
import { Container, Title, Subtitle } from './StyledElements';
import Button from '../../UIComponents/Button';
import { localize } from '../../components/language/config';

const NotFoundBlock = function () {
  return (
    <Container>
      <Title>{localize('Oups')}</Title>
      <Subtitle>{localize('The page you’re looking for could have been deleted or never have existed.')}</Subtitle>
      <Button url="/" top={30} label={localize('Back to home')} />
    </Container>
  );
};

export default NotFoundBlock;
