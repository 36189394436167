import * as React from 'react';
import NotFoundBlock from '../Blocks/NotFoundBlock';
import HeaderHtml from '../components/HeaderHtml';
import i18n, { localize } from '../components/language/config';
import { WithIntl } from '../components/language/withIntl';
import Layout from '../components/Layout';

const NotFoundPage = function () {
  const { language } = i18n;

  return (
    <Layout>
      <HeaderHtml
        language={language}
        title={localize('Page not found - Evolt')}
        description={localize('Create branded polls, surveys and user tests with the évolt feedback tool.')}
      />
      <NotFoundBlock />
    </Layout>
  );
};

export default WithIntl()(NotFoundPage);
