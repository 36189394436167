import styled from 'styled-components';

export const Container = styled.div`
  max-width:  1000px;
  width: 100%;
  margin: auto;
  height: calc(100vh - 85px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

export const Subtitle = styled.p`
  text-align: center;
`;
